import { IDefaultParamsOnline } from './../../types/IDefaultParamsOnline';
import { IOfferOnline } from './../../types/IOfferOnline';
import { CookieService } from "./cookieService";
export class RenderOnlineService {
  static renderOneOffer({ offer, params }: { offer: IOfferOnline; params: IDefaultParamsOnline }) {
    const cid = CookieService.getCookie('cid') ? '&CID=' + CookieService.getCookie('cid') : '';

    const available = offer.stockInfo ? params.available : params.noAvailable;

    let priceContent = '';
    if (params.showPrice) {
      priceContent = `
        <div class="wtb-retailer-item-price" style="color: ${params.widgetListColor}">
          ${offer.price} ${offer.retailer.currencySymbol}
        </div>
      `;
    }

    let awailability = '';
    if (params.showAvailability) {
      awailability = `
      <div class="wtb-retailer-item-available" style="color: ${params.widgetListColor}">
        ${available}
      </div>`
    }

    const offerContent = `
      <a href="${offer.purchaseLink + cid}" target="_blank" class="wtb-retailer-item" >
        <div class="wtb-retailer-item-logo">
          <img src="${offer.retailer.logo}" alt="">
        </div>
        ${awailability}
        ${priceContent}
        <div  class="wtb-retailer-item-link">
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.7669 9.04053L19.9188 14.1925L14.7669 19.3445" stroke="${params.widgetLinkColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.89185 14.2094H19.9527" stroke="${params.widgetLinkColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <circle cx="14.2095" cy="14.2095" r="13.2095" stroke="${params.widgetLinkColor}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </a>
    `;
    return offerContent;
  }

  static renderOffers({ offers, params }: { offers: IOfferOnline[]; params: IDefaultParamsOnline }) {
    let shopsListContent = '';
    if (offers.length) {
      offers.forEach(offer => {
        const shopContent = this.renderOneOffer({ offer, params });
        shopsListContent += shopContent;
      });
    } else {
      shopsListContent = `<div class="no-shops">${params.noShopsText}</div>`;
    }
    return shopsListContent;
  }

  static renderWidget({ offers, params }: { offers: IOfferOnline[]; params: IDefaultParamsOnline }) {
    const styles = params.buttonStyle;
    const buttonStyleArr = Object.keys(styles).map(prop => (`${prop}: ${styles[prop]};`));
    const buttonStyle = buttonStyleArr.join(' ');
    const date = new Date()
    const year = date.getFullYear();

    const shopsListContent = this.renderOffers({ offers, params });
    const widgetContent = `
      <button class="wtb-widget-button ${params.buttonClass ? params.buttonClass : 'wtb-widget-button-default'}" style="${buttonStyle}">
        ${params.buttonText}
      </button>
      <div class="wtb-widget">
        <div class="wtb-retailers-list">
          ${shopsListContent}
        </div>
          <div class="wtb-widget-footer" style="background: ${params.footerBg}">
            <div class="wtb-widget-copy" style="color: ${params.footerColor}">
              © ${year}
            </div>
            <a href="https://24ttl.net/?utm_source=widget" target="_blank" class="wtb-widget-logo">
              <svg width="598" height="136" viewBox="0 0 598 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M193.455 0L12.5439 0.0930869C5.29632 0.0930869 0 5.39904 0 12.6598V123.433C0 130.694 5.29632 136 12.5439 136L193.455 135.907C200.702 135.907 205.999 130.601 205.999 123.34V12.5667C206.092 5.30595 200.795 0 193.455 0ZM97.9354 88.2464V101.465H28.6187V78.2861C39.6759 75.4935 55.5649 71.4908 62.9983 69.3498C77.9581 64.9747 81.6748 61.3443 81.6748 53.9904C81.6748 45.5195 74.7059 41.4237 63.3699 41.4237C52.4986 41.4237 43.6714 45.0541 44.136 59.4825H27.3178C26.3887 42.3546 35.4946 28.0192 62.9054 28.0192C89.1082 28.0192 98.7716 39.0965 98.7716 52.9665C98.7716 63.6715 95.0549 72.7009 77.8651 78.0068C65.7858 81.7303 53.7994 84.0575 41.9059 86.1985V87.9671L97.9354 88.2464ZM181.933 93.8316H169.203V103.513H154.615V93.8316H107.599V71.3046C129.156 60.0411 143.372 39.7481 146.438 29.4155H165.58C153.872 51.384 132.965 72.3285 120.236 78.7515V81.358H154.615V63.0198H169.203V81.358H181.933V93.8316Z" fill="black"/>
                <path d="M369.324 31.22L354.072 93.716H351.592L338.82 44.612H322.948L311.292 93.716H308.812L292.568 31.22H275.58L296.908 105H322.08L330.76 72.264L339.688 105H364.86L385.32 31.22H369.324ZM464.012 31.22H389.612V44.24H419V105H434.624V44.24H464.012V31.22ZM532.508 66.312C538.708 63.956 542.8 57.756 542.8 50.564C542.8 38.908 534.492 31.22 520.728 31.22H472.74V105H521.348C536.352 105 545.28 96.94 545.28 84.664C545.28 76.232 541.312 69.536 532.508 66.312ZM527.052 51.928C527.052 58.004 523.58 61.848 516.264 61.848H487.62V42.628H516.264C522.96 42.628 527.052 45.852 527.052 51.928ZM518.248 93.468H487.62V73.008H518.496C525.564 73.008 529.532 76.604 529.532 83.052C529.532 89.748 525.316 93.468 518.248 93.468Z" fill="black"/>
                <rect x="223.5" y="1.5" width="373" height="133" rx="62.5" stroke="black" stroke-width="3"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
    `;

    return widgetContent;
  }
}