import { IOfferOnline } from "../../types/IOfferOnline";
import { IOffersOnlineRequest } from "../../types/IOffersOnlineRequest";
import { IOffersOfflineRequest } from '../../types/IOffersOfflineRequest';
import { IOffersOnlineList } from "../../types/IOffersOnlineList";

export class FetchService {
  static async getOffersOnline({ licenseId, productId, skuType, showAwailableOnly }: IOffersOnlineRequest) {
    const apiUrl = `${process.env.WIDGET_API_URL}/v1/${licenseId}/online/product?${skuType}=${productId}`;
    const response = await fetch(apiUrl);
    const data: IOffersOnlineList = await response.json();

    if (data.status === 200) {
      if (showAwailableOnly) {
        const availableOffers = data.offers.filter((offer: IOfferOnline) => (offer.stockInfo));
        return availableOffers;
      } else {
        return data.offers;
      }
    } else {
      console.error(data.statusDescription);
      return [];
    }
  }

  static async getOffersOffline({ licenseId, productId, skuType, lat, lng, distance }: IOffersOfflineRequest) {
    const apiUrl = `${process.env.WIDGET_API_URL}/v1/${licenseId}/offline/product/by-area?${skuType}=${productId}&latitude=${lat}&longitude=${lng}&distance=${distance}`;
    const response = await fetch(apiUrl);
    const data = await response.json();
    if (data.status === 200) {
      return data.stores;
    } else {
      return [];
    }
  }
}