import breakpoints from '../constants/breakpoints';

const sortedPointsNames = Object.keys(breakpoints);

export function makeResponsive(nodes: HTMLElement[]) {
  sortedPointsNames.forEach((name) => {
    nodes.forEach((node: HTMLElement) => {
      const wtbContainer = node.querySelector('#wtb-widget-offline');

      if (wtbContainer) {
        node.offsetWidth < breakpoints[name]
          ? wtbContainer.classList.add(name)
          : wtbContainer.classList.remove(name);
      }
    });
  });
}