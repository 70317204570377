import { IDefaultParamsOffline } from './../types/IDefaultParamsOffline';
import { IDefaultParamsOnline } from "../types/IDefaultParamsOnline";
import { IParamsOnline } from "../types/IParamsOnline";
import { defaultParamsOnline } from "./constants/defaultParamsOnline";
import { defaultParamsOffline } from "./constants/defaultParamsOffline";
import { checkParamsOnline } from "./helpers/checkParamsOnline";
import { checkWrapper } from "./helpers/checkWrapper";
import { FetchService } from "./services/fetchService";
import { RenderOfflineService } from "./services/renderOfflineService";
import { RenderOnlineService } from "./services/renderOnlineService";
import { MapService } from './services/mapService';
import { ResizeService } from './services/resizeService';

export class WtbWidget {
  map: any = null;

  defaultParams: IDefaultParamsOnline = defaultParamsOnline;
  defaultParamsOffline: IDefaultParamsOffline = defaultParamsOffline;

  init(params: IParamsOnline) {
    this.setInitialParams(params, 'defaultParams');
    const { el } = this.defaultParams;
    checkWrapper(el);
    this.createWidgetOnline();
  }

  initWidgetOnline(params: IParamsOnline) {
    this.setInitialParams(params, 'defaultParams');
    const { el } = this.defaultParams;
    checkWrapper(el);
    this.createWidgetOnline();
  }

  setInitialParams(params: any, defaultParams: string) {
    checkParamsOnline(params);
    //bad code
    if (defaultParams === 'defaultParams') {
      Object.keys(params).forEach((param: string) => {
        if(param === 'brandId') {
          this.defaultParams['licenseId'] = params[param];
        } else {
          this.defaultParams[param] = params[param];
        }
      });
    } else if (defaultParams === 'defaultParamsOffline') {
      Object.keys(params).forEach((param: string) => {
        if(param === 'brandId') {
          this.defaultParamsOffline['licenseId'] = params[param];
        } else {
          this.defaultParamsOffline[param] = params[param];
        }
      });
    }
    //bad code
  }

  async createWidgetOnline() {

    const { licenseId, productId, skuType, showAwailableOnly } = this.defaultParams;
    const offers = await FetchService.getOffersOnline({ licenseId, productId, skuType, showAwailableOnly });
    const widgetContent = RenderOnlineService.renderWidget({ offers, params: this.defaultParams });
    const wtbContainerList = document.querySelectorAll(this.defaultParams.el);
    wtbContainerList.forEach((wtbContainer: Node) => {
      const widget = document.createElement('div');
      widget.classList.add('wtb-widget-outer');
      if (this.defaultParams.widgetClass) {
        widget.classList.add(this.defaultParams.widgetClass);
      }
      widget.innerHTML = widgetContent;
      while(wtbContainer.firstChild && wtbContainer.removeChild(wtbContainer.firstChild));
      wtbContainer.appendChild(widget);
      
    });

    this.toggleWidgetOnlineDisplay();
  }

  toggleWidgetOnlineDisplay() {
    document.onclick = (e: any) => {
      const openWidget = document.querySelector('.wtb-widget.opened');
      if (openWidget && !e.target.classList.contains('wtb-widget') && !e.target.closest('.wtb-widget')) {
        openWidget.classList.remove('opened');
      }
      if (e.target.classList.contains('wtb-widget-button')) {
        e.preventDefault();
        e.target.closest('.wtb-widget-outer').querySelector('.wtb-widget').classList.add('opened');
      }
    }
  }

  async initWidgetOffline(params: IDefaultParamsOffline) {
    this.setInitialParams(params, 'defaultParamsOffline');
    const { el } = this.defaultParamsOffline;
    checkWrapper(el);
    const mapService = new MapService();
    // await mapService.addMapScript();
    this.createWidgetOffline();
    mapService.mapInit(this.defaultParamsOffline);
    const container = document.querySelector(this.defaultParamsOffline.el);
    if(container) {
      const resizeService = new ResizeService(container);
    }
  }

  async createWidgetOffline() {
    const wtbContainer = document.querySelector(this.defaultParamsOffline.el);
    if (wtbContainer) {
      wtbContainer.innerHTML = RenderOfflineService.renderWidget({ params: this.defaultParamsOffline });

    }
  }

  

  
}