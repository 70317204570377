import ResizeObserver from 'resize-observer-polyfill';
import { makeResponsive } from "../helpers/makeResponsive";

export class ResizeService {
  constructor(elem) {
    const widthObserver = new ResizeObserver(this.resizeObserverCallback);
    widthObserver.observe(elem);
  }

  resizeObserverCallback(entries: any) {
    makeResponsive(entries.map((entry: any) => entry.target));
  }
}