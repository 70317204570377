import { skuTypes } from "../constants/skuTypes";

export  function checkParamsOnline(params: any) {
  if (params.hasOwnProperty('skuType') && !skuTypes.includes(params.skuType)) {
    throw new Error('Wrong skuType');
  }

  if (!params.hasOwnProperty('productId')) {
    throw new Error('productId is required');
  }

  if (!params.hasOwnProperty('licenseId') && !params.hasOwnProperty('brandId')) {
    throw new Error('licenseId is required');
  }
  return params;
};
