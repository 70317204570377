import { IDefaultParamsOffline } from "../../types/IDefaultParamsOffline";
import { IOfferOffline } from "../../types/IOfferOffline";

export class RenderOfflineService {
  static renderWidget({ params }: {params: IDefaultParamsOffline }) {
    const widgetContent = `
      <div id="wtb-widget-offline" class="wtb-widget-offline" style="background: ${params.bgColor};">
        <div class="wtb-widget-offline-content">
          <div class="wtb-widget-sidebar">
            <div class="wtb-widget-stores">
              <div class="wtb-widget-stores-title" style="border-color: ${params.borderColor};">
                ${params.titleText}
              </div>
              <div class="wtb-widget-stores-list">
              </div>
            </div>
            ${this.renderStoreInfo(params)}
          </div>
          <div class="wtb-widget-map" id="wtb-widget-map"></div>
        </div>
        <div class="wtb-widget-footer" style="background: ${params.footerBg}; color: ${params.footerColor};">
          <div class="wtb-widget-footer-text">
            ${params.disclaimerText}
          </div>
          <a href="https://24ttl.net/?utm_source=widget" target="_blank" class="wtb-widget-footer-logo">
            <svg width="598" height="136" viewBox="0 0 598 136" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M193.455 0L12.5439 0.0930869C5.29632 0.0930869 0 5.39904 0 12.6598V123.433C0 130.694 5.29632 136 12.5439 136L193.455 135.907C200.702 135.907 205.999 130.601 205.999 123.34V12.5667C206.092 5.30595 200.795 0 193.455 0ZM97.9354 88.2464V101.465H28.6187V78.2861C39.6759 75.4935 55.5649 71.4908 62.9983 69.3498C77.9581 64.9747 81.6748 61.3443 81.6748 53.9904C81.6748 45.5195 74.7059 41.4237 63.3699 41.4237C52.4986 41.4237 43.6714 45.0541 44.136 59.4825H27.3178C26.3887 42.3546 35.4946 28.0192 62.9054 28.0192C89.1082 28.0192 98.7716 39.0965 98.7716 52.9665C98.7716 63.6715 95.0549 72.7009 77.8651 78.0068C65.7858 81.7303 53.7994 84.0575 41.9059 86.1985V87.9671L97.9354 88.2464ZM181.933 93.8316H169.203V103.513H154.615V93.8316H107.599V71.3046C129.156 60.0411 143.372 39.7481 146.438 29.4155H165.58C153.872 51.384 132.965 72.3285 120.236 78.7515V81.358H154.615V63.0198H169.203V81.358H181.933V93.8316Z" fill="black"></path>
              <path d="M369.324 31.22L354.072 93.716H351.592L338.82 44.612H322.948L311.292 93.716H308.812L292.568 31.22H275.58L296.908 105H322.08L330.76 72.264L339.688 105H364.86L385.32 31.22H369.324ZM464.012 31.22H389.612V44.24H419V105H434.624V44.24H464.012V31.22ZM532.508 66.312C538.708 63.956 542.8 57.756 542.8 50.564C542.8 38.908 534.492 31.22 520.728 31.22H472.74V105H521.348C536.352 105 545.28 96.94 545.28 84.664C545.28 76.232 541.312 69.536 532.508 66.312ZM527.052 51.928C527.052 58.004 523.58 61.848 516.264 61.848H487.62V42.628H516.264C522.96 42.628 527.052 45.852 527.052 51.928ZM518.248 93.468H487.62V73.008H518.496C525.564 73.008 529.532 76.604 529.532 83.052C529.532 89.748 525.316 93.468 518.248 93.468Z" fill="black"></path>
              <rect x="223.5" y="1.5" width="373" height="133" rx="62.5" stroke="black" stroke-width="3"></rect>
            </svg>
          </a>
        </div>
      </div>
    `;

    return widgetContent;
  }

  static renderStoreInfo(params: IDefaultParamsOffline) {
    const storeDetails = `
    <div class="wtb-widget-store-info">
          <div class="wtb-widget-store-info-close">
            <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M60.243 84.389l-38.019-36.39 38.019-36.388 5.532 5.779-31.981 30.609 31.981 30.612z"></path></svg>
            ${params.backText}
          </div>
          <div class="wtb-widget-store-info-title"></div>
          
          <div class="wtb-widget-store-info-contacts">
            <a href="javascript:void(0);" class="wtb-widget-store-info-contacts-item wtb-widget-store-info-contacts-item--address">
              <div class="wtb-widget-store-info-contacts-item-img">
                <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M48 1.5c20.982 0 38 16.927 38 37.819 0 8.759-2.997 16.687-8.588 23.482l-.048.06-25.566 29.891a5.002 5.002 0 01-7.598-.001L18.59 62.8C12.996 56.003 10 48.076 10 39.318c0-20.892 17.018-37.82 38-37.82zm0 8c-16.574 0-30 13.355-30 29.819 0 6.853 2.323 12.998 6.718 18.34L48 84.885l23.222-27.151.359-.443c4.103-5.153 6.298-10.975 6.414-17.433l.005-.54C78 22.856 64.574 9.5 48 9.5zm-.023 8.73c11.599 0 21 9.403 21 21 0 11.598-9.401 21-21 21-11.596 0-21-9.403-21-21 0-11.596 9.404-21 21-21zm0 7c-7.73 0-14 6.27-14 14 0 7.731 6.27 14 14 14 7.733 0 14-6.268 14-14 0-7.731-6.267-14-14-14z" fill="${params.widgetColor}"/></svg>
              </div>
              <div class="wtb-widget-store-info-contacts-item-text" style="color:${params.widgetColor};">-</div>
            </a>
            <a href="javascript:void(0);" class="wtb-widget-store-info-contacts-item wtb-widget-store-info-contacts-item--phone">
              <div class="wtb-widget-store-info-contacts-item-img">
                <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M13.12 21.262c1.921 15.578 9.078 30.236 20.23 41.386 11.152 11.154 25.811 18.31 41.39 20.231 1.685-1.374 5.354-5.388 8.272-8.906v-5.51L63.98 64.159l-4.607 4.606c-1.64 1.64-4.305 2.02-6.337.904 0 0-.119-.065-.67-.386a69.806 69.806 0 01-25.58-25.523c-.336-.58-.453-.792-.453-.792-1.118-2.032-.739-4.7.903-6.34l4.607-4.608-4.307-19.03h-5.512c-3.518 2.918-7.53 6.588-8.905 8.273zm62.601 69.75a6.34 6.34 0 01-.714-.05c-17.803-1.9-34.607-9.948-47.313-22.656C14.985 55.599 6.938 38.796 5.036 20.99c-.192-1.791-.432-4.023 12.238-14.454 1.142-.94 2.836-1.548 4.315-1.548h8.251a5.126 5.126 0 015.05 4.244l4.974 21.981c.551 1.919.002 4.096-1.414 5.511l-4.1 4.103A61.84 61.84 0 0055.172 61.65l4.1-4.1c1.416-1.416 3.594-1.964 5.51-1.413l21.985 4.974a5.124 5.124 0 014.245 5.047v8.25c0 1.48-.608 3.173-1.548 4.316-9.116 11.077-11.97 12.287-13.743 12.287z" fill="${params.widgetColor}"/></svg>
              </div>
              <div class="wtb-widget-store-info-contacts-item-text" style="color:${params.widgetColor};">-</div>
            </a>
            <a href="javascript:void(0);" class="wtb-widget-store-info-contacts-item wtb-widget-store-info-contacts-item--mail">
              <div class="wtb-widget-store-info-contacts-item-img">
                <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 21.9216V20.5H89.5V21.9217L48 54.1994L6.5 21.9216ZM6.5 29.5228V75.5H89.5V29.5228L51.0697 59.4131C49.2642 60.8174 46.7359 60.8174 44.9303 59.4131L6.5 29.5228ZM0 20C0 16.6863 2.68629 14 6 14H90C93.3137 14 96 16.6863 96 20V76C96 79.3137 93.3137 82 90 82H6C2.68629 82 0 79.3137 0 76V20Z" fill="${params.disclaimerShopColor}"/></svg>
              </div>
              <div class="wtb-widget-store-info-contacts-item-text" style="color:${params.widgetColor};">-</div>
            </a>
          </div>
          <div class="wtb-widget-store-info-note" style="color: ${params.disclaimerShopColor}; border-color: ${params.borderColor};">
            <div class="wtb-widget-store-info-note-img">
              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M48 2.5c25.129 0 45.5 20.371 45.5 45.501S73.13 93.5 48 93.5 2.5 73.13 2.5 48.001C2.5 22.871 22.871 2.5 48 2.5zm0 5C25.633 7.5 7.5 25.633 7.5 48.001S25.632 88.5 48 88.5s40.5-18.131 40.5-40.499C88.5 25.633 70.367 7.5 48 7.5zm2.5 31V75h-5V43.5H40v-5h10.5zm-3.502-16.595C49.209 21.905 51 23.737 51 26c0 2.262-1.791 4.095-4.002 4.095C44.79 30.095 43 28.262 43 26c0-2.263 1.79-4.095 3.998-4.095z" fill="${params.disclaimerShopColor}"></path></svg>
            </div>
            ${params.disclaimerShopText}
          </div>
        </div>
    `;
    return storeDetails;
  }

  static renderStore({ offer, params }: { offer: IOfferOffline; params: IDefaultParamsOffline }) {
    const storeContent = `
      <div data-id="${offer.id}" class="wtb-widget-stores-list-item" data-lat="${offer.latitude}" data-lng="${offer.longitude}" data-address="${offer.address ? offer.address : '-'}" data-phone="${offer.telephone ? offer.telephone : '-'}" data-email="${offer.email ? offer.email : '-'}" style="--hoverbg: ${params.hoverColor};">
        <div class="wtb-widget-stores-list-item-content" style="color: ${params.textColor}; border-color: ${params.borderColor};">
          <div class="wtb-widget-stores-list-item-title">
            ${offer.name}
          </div>
          <div class="wtb-widget-stores-list-item-distance">
          </div>
          ${offer.address}
          <div class="wtb-widget-stores-list-item-arrow">
            <svg viewBox="0 0 96 96" id="next-bold" xmlns="http://www.w3.org/2000/svg"><path d="M35.757 84.389l-5.533-5.778 31.982-30.612L30.224 17.39l5.533-5.779 38.018 36.388z"/></svg>
          </div>
        </div>
      </div>
    `;
    return storeContent;
  }

  static renderStoresList({ offers, params }: { offers: IOfferOffline[]; params: IDefaultParamsOffline }) {
    let storesListContent = '';
    offers.forEach(offer => {
      storesListContent += this.renderStore({ offer, params });
    });
    return storesListContent;
  }
}
