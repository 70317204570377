import { IDefaultParamsOnline } from "../../types/IDefaultParamsOnline";

export const defaultParamsOnline: IDefaultParamsOnline = {
  licenseId: '',
  el: '.wtb-container',
  skuType: 'mpn',
  productId: '',
  buttonText: 'Где купить',
  buttonClass: null,
  buttonStyle: {},
  widgetClass: null,
  widgetLinkColor: '#ccc',
  widgetListColor: '#000',
  footerColor: '#736c64',
  footerBg: '#ddd',
  noShopsText: 'У данного товара нет предложений',
  available: 'В наличии',
  noAvailable: 'Нет в наличии',
  showPrice: false,
  showAvailability: false,
  showAwailableOnly: true,
}
