import { IDefaultParamsOffline } from './../../types/IDefaultParamsOffline';

export const defaultParamsOffline: IDefaultParamsOffline = {
  licenseId: '',
  el: '.wtb-container',
  skuType: 'mpn',
  productId: '',
  titleText: 'СПИСОК ВСЕХ <span class="wtb-widget-stores-count">0</span> МАГАЗИНОВ',
  backText: 'НАЗАД',
  disclaimerText: 'Информация о наличии продуктов и цены могут отличаться. За актуальной информацией, пожалуйста, обращайтесь напрямую к продавцу.',
  disclaimerShopText: 'Информация на этой странице может быть изменена без предварительного уведомления. Наличие на складе и цены могут отличаться, поэтому, пожалуйста, свяжитесь с продавцом напрямую для получения актуальной информации.',
  center: {
    lat: 55.753809,
    lng: 37.621872,
  },
  distance: 100000,
  widgetColor: '#000000',
  textColor: '#0000000',
  bgColor: '#ffffff',
  hoverColor: '#e5e5e5',
  disclaimerShopColor: '#757575',
  borderColor: '#e5e5e5',
  footerColor: '#202427',
  footerBg: '#e8e8e8',
  extLinkShow: true,
  extLinkText: 'Oткрыть в 2Gis',

};